.firstsec {
  position: relative;
}

.firstsec-mobile {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 40%);
}

/* Media query to remove gradient on desktop */
@media (min-width: 768px) {
  .firstsec-mobile {
    background: none;
  }
}
.bg-pattern {
  position: relative;
  overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
}

.bg-pattern::before {
  content: "";
  background-image: url("../../assets/imgs/Vector.svg"); /* Update the path as needed */
  background-repeat: repeat;
  background-size: 15%;
  position: absolute;
  top: -15px; /* Adjust top position as needed */
  left: -5px; /* Adjust left position as needed */
  right: 0;
  bottom: 0;
  width: 150%; /* Ensures full coverage */
  height: 150%; /* Ensures full coverage */
  z-index: 0; /* Places the pseudo-element behind the content */
  filter: opacity(0.2); /* Reduces opacity of only the background image */
}

.swiper-button-next,
.swiper-button-prev {
  color: #6b46c1; /* Adjust to your theme color */
}

.swiper-pagination-bullet-active {
  background-color: #6b46c1; /* Adjust to your theme color */
}
